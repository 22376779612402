var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var IL,KL,ML,PL,UL;$CLJS.EL=function(a){return $CLJS.Wh($CLJS.q($CLJS.nC),a,$CLJS.uG)};$CLJS.FL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.GL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.HL=new $CLJS.M(null,"object-id","object-id",-754527291);IL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.JL=new $CLJS.M(null,"operators","operators",-2064102509);
KL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.LL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);ML=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.NL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.OL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
PL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.QL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.RL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.SL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.TL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
UL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.VL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.lH],null)],null)],null));$CLJS.X(UL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,KL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.JE],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.qG],null),UL,$CLJS.AE],null));
$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.qG],null),KL,$CLJS.nL],null));
$CLJS.QG.g($CLJS.qG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.qG],null),KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,$CLJS.nL,$CLJS.AE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,2,[$CLJS.Ri,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.lC(a)):null},$CLJS.xt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.pB,PL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oB,ML],null)],null)],null));$CLJS.yF($CLJS.qG,$CLJS.uG);$CLJS.UE.m(null,$CLJS.qG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Hi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.FF($CLJS.vB,$CLJS.H([$CLJS.AE]));$CLJS.UE.m(null,$CLJS.vB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Hi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.gF});
$CLJS.yF($CLJS.vB,$CLJS.uG);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.FE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.AE],null)],null)],null));$CLJS.QG.g($CLJS.fG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.fG],null),IL,$CLJS.ck],null));
$CLJS.UE.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.GA($CLJS.Hi,$CLJS.yB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.yF($CLJS.fG,$CLJS.uG);$CLJS.FF($CLJS.PF,$CLJS.H([$CLJS.qt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null)]));$CLJS.yF($CLJS.PF,$CLJS.uG);
$CLJS.FF($CLJS.lG,$CLJS.H([$CLJS.qt,$CLJS.gF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null)],null)]));$CLJS.yF($CLJS.lG,$CLJS.uG);
$CLJS.X($CLJS.uG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.WE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.It(", ",$CLJS.bH($CLJS.Jd,$CLJS.pl.g($CLJS.q($CLJS.nC),$CLJS.uG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.EL(a)}],null)],null));