var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Usa,Vsa,Xsa,Ysa,Zsa,$sa;Usa=function(a,b){return $CLJS.MA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Vsa=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Xsa=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Tk.g($CLJS.pE(function(b){return $CLJS.$W(b,a)}),$CLJS.hf.h($CLJS.hd)),Wsa)};
Ysa=function(a){return new $CLJS.h(null,3,[$CLJS.pj,$CLJS.CB,$CLJS.T,a,$CLJS.bj,$CLJS.p.h($CLJS.RE())],null)};
Zsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.kl.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.AW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DN,$CLJS.kR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.pj,$CLJS.OM,$CLJS.AM,c],null):null}(),$CLJS.n($CLJS.vE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.vE,$CLJS.I1.g($CLJS.DB,b)],null)]))};$CLJS.I8=function(a){a=$CLJS.B1($CLJS.a0(a));return $CLJS.n($CLJS.HA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mA,$sa],null)))?$CLJS.be.g($CLJS.bh,$CLJS.tO):$CLJS.bh};$CLJS.J8=function(a,b){var c=$CLJS.I8(a);return(0,$CLJS.XW)(a,0,function(d){var e=$CLJS.mu.g(ata,c);d=$CLJS.Se.j($CLJS.Lk,d,e);d=$CLJS.kl.l($CLJS.H([d,$CLJS.im(b,c)]));$CLJS.mu.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.MW(a,0);return d})};$CLJS.K8=function(a){return $CLJS.Fe($CLJS.im($CLJS.MW(a,0),$CLJS.I8(a)))};
$CLJS.L8=function(a){return $CLJS.rQ.h($CLJS.MW(a,0))};$sa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.bta=new $CLJS.M(null,"write","write",-1857649168);$CLJS.cta=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Wsa,ata;Wsa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.M8=function(){function a(d,e){d=$CLJS.Fe(Xsa(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.mu.g(d,f);d=$CLJS.mu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.vE.h(f),$CLJS.I1.g($CLJS.DB,d))?$CLJS.I1.g($CLJS.DB,k):$CLJS.vE.h(f);f=$CLJS.R.l($CLJS.Lk.l(f,$CLJS.AM,$CLJS.H([$CLJS.kR,$CLJS.aQ])),$CLJS.vE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Lk.g(e,d),k,f)}else e=$CLJS.kl.l($CLJS.H([Usa($CLJS.Te(d),
e),Vsa($CLJS.T,$CLJS.hf.g(Ysa,k))]));e=$CLJS.Ht(e,Zsa)}else e=$CLJS.N;return e}function b(d){return $CLJS.M8.g?$CLJS.M8.g(d,null):$CLJS.M8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();ata=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.tO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.AE],null)],null));
$CLJS.N8=function(){function a(d,e,f,k){var l=$CLJS.M8.h(e);return $CLJS.J8($CLJS.F1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.eu,$CLJS.iW,$CLJS.vV,f,$CLJS.OQ,l,$CLJS.rQ,e],null)],null)),k)}function b(d,e){return $CLJS.N8.v?$CLJS.N8.v(d,e,null,null):$CLJS.N8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.O8=function(){function a(d,e,f){$CLJS.MW(d,0);return $CLJS.J8($CLJS.F1.g(e,$CLJS.wL.h(d)),f)}function b(d,e){return $CLJS.O8.j?$CLJS.O8.j(d,e,null):$CLJS.O8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E1.m(null,$CLJS.iW,function(a){return $CLJS.y1($CLJS.I8(a),$CLJS.fh($CLJS.Xg($CLJS.K8(a))))&&!$CLJS.LA($CLJS.L8(a))});