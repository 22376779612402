var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var t2=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.SE("Equal to");case "default":return $CLJS.SE("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.SE("Not equal to");case "excludes":return $CLJS.SE("Excludes");case "default":return $CLJS.SE("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.SE("After");case "default":return $CLJS.SE("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.SE("Before");case "default":return $CLJS.SE("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.SE("Is empty");
case "default":return $CLJS.SE("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.SE("Not empty");case "default":return $CLJS.SE("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.SE("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},u2=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return t2(a,b)}},moa=new $CLJS.M(null,"after","after",594996914),noa=new $CLJS.M(null,"equal-to","equal-to",608296653),ooa=new $CLJS.M(null,"excludes","excludes",-1791725945),poa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),qoa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.v2=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.eu,$CLJS.$J,$CLJS.jG,d,$CLJS.aK,e],null)}function b(d){return c.g?c.g(d,$CLJS.ci):c.call(null,d,$CLJS.ci)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.w2=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.Zr),$CLJS.v2.h($CLJS.cs),$CLJS.v2.h($CLJS.TF),$CLJS.v2.h($CLJS.as),$CLJS.v2.h($CLJS.es),$CLJS.v2.g($CLJS.SF,$CLJS.UF),$CLJS.v2.g($CLJS.cG,$CLJS.aG)],null);$CLJS.roa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.UF),$CLJS.v2.h($CLJS.aG),$CLJS.v2.h($CLJS.pG),$CLJS.v2.h($CLJS.iG),$CLJS.v2.h($CLJS.hG),$CLJS.v2.h($CLJS.BG)],null);
$CLJS.soa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.v2.g($CLJS.HG,ooa),$CLJS.v2.h($CLJS.fs),$CLJS.v2.g($CLJS.cs,qoa),$CLJS.v2.g($CLJS.Zr,moa),$CLJS.v2.h($CLJS.TF),$CLJS.v2.g($CLJS.SF,$CLJS.UF),$CLJS.v2.g($CLJS.cG,$CLJS.aG)],null);$CLJS.toa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.ZF),$CLJS.v2.h($CLJS.Zr),$CLJS.v2.h($CLJS.cs),$CLJS.v2.h($CLJS.TF),$CLJS.v2.h($CLJS.as),$CLJS.v2.h($CLJS.es)],null);
$CLJS.uoa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.v2.g($CLJS.fs,noa),$CLJS.v2.g($CLJS.HG,poa),$CLJS.v2.h($CLJS.Zr),$CLJS.v2.h($CLJS.cs),$CLJS.v2.h($CLJS.TF),$CLJS.v2.h($CLJS.as),$CLJS.v2.h($CLJS.es),$CLJS.v2.g($CLJS.SF,$CLJS.UF),$CLJS.v2.g($CLJS.cG,$CLJS.aG)],null);
$CLJS.voa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.pG),$CLJS.v2.h($CLJS.iG),$CLJS.v2.h($CLJS.SF),$CLJS.v2.h($CLJS.cG),$CLJS.v2.h($CLJS.UF),$CLJS.v2.h($CLJS.aG),$CLJS.v2.h($CLJS.hG),$CLJS.v2.h($CLJS.BG)],null);$CLJS.woa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.SF),$CLJS.v2.h($CLJS.cG),$CLJS.v2.h($CLJS.UF),$CLJS.v2.h($CLJS.aG)],null);
$CLJS.xoa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.g($CLJS.SF,$CLJS.UF),$CLJS.v2.g($CLJS.cG,$CLJS.aG)],null);$CLJS.yoa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.v2.h($CLJS.fs),$CLJS.v2.h($CLJS.HG),$CLJS.v2.h($CLJS.SF),$CLJS.v2.h($CLJS.cG)],null);$CLJS.x2=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.v2.h($CLJS.fs),$CLJS.ci,!0),$CLJS.v2.h($CLJS.Zr),$CLJS.v2.h($CLJS.cs),$CLJS.v2.h($CLJS.as),$CLJS.v2.h($CLJS.es),$CLJS.v2.h($CLJS.HG)],null);
$CLJS.H0.m(null,$CLJS.$J,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.jG);b=$CLJS.J.g(b,$CLJS.aK);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return u2(a,b);case "long":return t2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.O0.m(null,$CLJS.$J,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.jG);b=$CLJS.J.g(c,$CLJS.aK);c=$CLJS.J.g(c,$CLJS.ci);a=new $CLJS.h(null,3,[$CLJS.F0,$CLJS.dB(a),$CLJS.vE,u2(a,b),$CLJS.j0,t2(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});