var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var cX,dX,fX,gX,fla,iX,jX,bX,kX,gla,hla,ila,jla,oX,pX,rX,sX,tX,uX,vX,wX,lla,mla,yX,nla,zX,ola,BX,pla,qla,rla,sla,tla,ZW;$CLJS.$W=function(a,b){if("string"===typeof b)return ZW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.aX=function(a,b,c){var d=$CLJS.jm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
cX=function(a){var b=bX;return $CLJS.MA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};dX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ji,a],null));};
$CLJS.eX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Vk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};fX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
gX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
fla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.$k.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.$k.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
iX=function(a,b){for(;;)switch(b=$CLJS.ml.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),hX.h?hX.h(a):hX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(gX,a),b))){var c=a;b=fla(a,b);a=c}else{c=$CLJS.hf.g(hX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};jX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.GA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
bX=function(a){return $CLJS.n($CLJS.GA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.KA(fX(a).toLowerCase(),/_/,"-")):a};kX=function(a,b){var c=jX(b);return $CLJS.n(c)?(b=bX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};gla=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.pj,bX,$CLJS.YN,bX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=lX.g?lX.g(b,mX):lX.call(null,b,mX);return d.call(c,b,a)})};
hla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=bX(c);var d=gla(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.pj.h(a),$CLJS.KF)&&$CLJS.Va($CLJS.YN.h(a))?$CLJS.R.j(a,$CLJS.YN,$CLJS.JG):a};ila=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=fX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(hla(b),$CLJS.T,c)],null)}),a)};
jla=function(a){a=lX.h?lX.h(a):lX.call(null,a);return nX.h?nX.h(a):nX.call(null,a)};oX=function(a){return $CLJS.aX($CLJS.aX($CLJS.fb(function(b,c){return $CLJS.aX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mD,$CLJS.Dea,$CLJS.LO,kla,$CLJS.gA,$CLJS.si],null)),$CLJS.mC,jla),$CLJS.$N,$CLJS.CV)};pX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,a,null],null):a};rX=function(a){return qX.h(pX(a))};
sX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return hX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(qX,b)))};tX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,rX(b)],null),$CLJS.hf.g(qX,c))};uX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,rX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
vX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,rX(a)],null)};wX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,qX.h(a)],null)};lla=function(a){return $CLJS.Df($CLJS.gm(nX,xX(a)))};mla=function(a){for(;;)if($CLJS.n(jX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Vk.g(pX,a))};
yX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.KF);return $CLJS.n(b)?$CLJS.Wk.j(a,$CLJS.KF,qX):a};
nla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,yX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,yX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};zX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OQ);return $CLJS.n(b)?$CLJS.Wk.j(a,$CLJS.OQ,nla):a};ola=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.rQ);b=$CLJS.Va(a)?AX.h?AX.h(b):AX.call(null,b):b;return $CLJS.n(a)?zX(b):b};BX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
pla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.NF);var c=$CLJS.J.g(b,$CLJS.CN),d=$CLJS.J.g(b,$CLJS.ZO),e=$CLJS.J.g(b,$CLJS.rQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.ZO);var l=$CLJS.dM($CLJS.Lk.g(f,$CLJS.ZO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,$CLJS.ZO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Wk.j(l,$CLJS.NF,AX):l,t=$CLJS.n(c)?$CLJS.Wk.j(m,$CLJS.CN,$CLJS.Ve($CLJS.Vk,nX)):m,u=$CLJS.n(e)?$CLJS.Wk.j(t,$CLJS.rQ,zX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.rQ);return $CLJS.n(x)?$CLJS.R.j(nX($CLJS.Lk.g(v,
$CLJS.rQ)),$CLJS.rQ,x):nX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.qE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.AV(m)])),new $CLJS.h(null,1,[$CLJS.NF,a],null),m);throw z;}};
qla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.NF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.eG);c=$CLJS.J.g(c,$CLJS.$E);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.yC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.qG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,m,$CLJS.Lk.g(t,$CLJS.wG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.HV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.CX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,$CLJS.$E],null),$CLJS.Tk.g($CLJS.Df,$CLJS.Ve($CLJS.Uk,b)))):a};
rla=function(a){try{return qla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.SE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.NF,a],null),b);}throw c;}};
sla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=DX.g?DX.g(C,K):DX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return DX.g?DX.g(z,C):DX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};tla=function(a,b){a=$CLJS.Vk.g(function(c){var d=$CLJS.be.g(b,EX);return DX.g?DX.g(c,d):DX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
ZW=function ZW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),ZW.g?ZW.g(a,k):ZW.call(null,a,k)):null},null,null))};
$CLJS.CX=function CX(a){switch(arguments.length){case 3:return CX.j(arguments[0],arguments[1],arguments[2]);case 4:return CX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return CX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return CX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.CX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.CX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.CX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.CX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.CX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.CX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.CX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.CX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.CX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.CX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.CX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.CX.A=6;
var hX=function hX(a){for(;;){if($CLJS.xd(a))return $CLJS.Ht(a,hX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ql(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return iX($CLJS.Bs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Ds,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return iX($CLJS.Hs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return iX($CLJS.Hs,t);case "or":return iX($CLJS.Bs,t);default:return $CLJS.Vk.g(hX,a)}}else return a}},ula=new $CLJS.M(null,"value_field","value_field",-980977878),FX=new $CLJS.M(null,"ascending","ascending",-988350486),
GX=new $CLJS.M(null,"named","named",-422393479),HX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),IX=new $CLJS.M(null,"descending","descending",-24766135),JX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),vla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),KX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),LX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),wla=new $CLJS.M(null,"rows","rows",850049680),xla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),EX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),mX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),yla=new $CLJS.M(null,"label_field","label_field",-1573182765),zla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ala=new $CLJS.M(null,"joined-field","joined-field",-2048778268),kla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var MX,Bla=$CLJS.Xe($CLJS.N),Cla=$CLJS.Xe($CLJS.N),Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.Xe($CLJS.N),Fla=$CLJS.J.j($CLJS.N,$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));MX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Tk.g(bX,$CLJS.A),Fla,Bla,Cla,Dla,Ela);MX.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,b],null);return null!=a?$CLJS.be.g(b,a):b});
MX.m(null,$CLJS.vB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vB,a instanceof $CLJS.M?fX(a):a],null)});MX.m(null,$CLJS.JV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(MX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JV,lX.g?lX.g(b,mX):lX.call(null,b,mX),bX(c)],null)});
MX.m(null,$CLJS.qG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=lX.g?lX.g(a,mX):lX.call(null,a,mX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,b,function(){var d=$CLJS.n($CLJS.yB.h(c))?$CLJS.Wk.j(c,$CLJS.yB,$CLJS.zh):c;d=$CLJS.n($CLJS.wG.h(c))?$CLJS.Wk.j(d,$CLJS.wG,$CLJS.zh):d;return $CLJS.n($CLJS.CR.h(c))?$CLJS.Wk.j(d,$CLJS.CR,function(e){return $CLJS.n($CLJS.LE.h(e))?$CLJS.Wk.j(e,$CLJS.LE,$CLJS.zh):e}):d}()],null)});
MX.m(null,KX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[KX,b instanceof $CLJS.M?fX(b):b,$CLJS.zh.h(a)],null)});MX.m(null,JX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[JX,lX.g?lX.g(b,mX):lX.call(null,b,mX),$CLJS.ot,bX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[JX,lX.g?lX.g(b,mX):lX.call(null,b,mX),bX(c)],null)});
MX.m(null,$CLJS.CG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(MX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.CG,b,c,d],null)),lX.g?lX.g(a,mX):lX.call(null,a,mX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.CG,lX.g?lX.g(b,mX):lX.call(null,b,mX),$CLJS.Fd(c)?c:bX(c),bX(d)],null)});
MX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,b,bX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,$CLJS.$v],null)});MX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,b,bX(a)],null)});
MX.m(null,$CLJS.qJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),c,bX(a)],null)});MX.m(null,$CLJS.wJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),c,bX(a)],null)});
MX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),bX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,lX.g?lX.g(b,mX):lX.call(null,b,mX)],null)});
MX.m(null,$CLJS.uJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),bX(c),bX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),bX(c)],null)});
MX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),lX.g?lX.g(c,mX):lX.call(null,c,mX),bX(a)],null)});MX.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,b,a],null)});
MX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[bX(a)],null),$CLJS.hf.h(function(c){return lX.g?lX.g(c,mX):lX.call(null,c,mX)}),b)});
var NX=function NX(a){if($CLJS.n($CLJS.GA($CLJS.ge,$CLJS.Ya)(a))){var c=bX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.co,null,$CLJS.is,null,$CLJS.FG,null,$CLJS.zG,null,$CLJS.qt,null,$CLJS.rG,null,$CLJS.KG,null,$CLJS.LG,null,$CLJS.WF,null,$CLJS.bG,null,$CLJS.hs,null,$CLJS.XF,null,$CLJS.EG,null,$CLJS.xG,null,$CLJS.nk,null,$CLJS.Yx,null,$CLJS.kG,null,$CLJS.DG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(jX(a))?(a=$CLJS.A(a),NX.h?NX.h(a):NX.call(null,
a)):null},Gla=new $CLJS.h(null,8,[$CLJS.pj,bX,$CLJS.rQ,function(a){a=cX(a);return $CLJS.y($CLJS.OQ.h(a))?$CLJS.Wk.j(a,$CLJS.OQ,ila):a},$CLJS.NF,new $CLJS.h(null,6,[$CLJS.fG,function OX(a){if($CLJS.n($CLJS.GA($CLJS.ge,$CLJS.Ya)(a)))return bX(a);if($CLJS.n(kX(GX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[GX,OX.h?OX.h(a):OX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(jX(a))?NX($CLJS.hd(a)):null)?$CLJS.Vk.g(OX,a):lX.g?lX.g(a,
mX):lX.call(null,a,mX)},$CLJS.gQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[fX(u),lX.g?lX.g(t,mX):lX.call(null,t,mX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[fX(l),lX.g?lX.g(f,mX):lX.call(null,f,mX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.WR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(jX(t))?MX.h(t):$CLJS.ce(MX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(jX(l))?MX.h(l):$CLJS.ce(MX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.QQ,function(a){a=cX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.rQ);if($CLJS.n(b))return a=$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.rQ,$CLJS.NF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rQ],null),a=lX.g?lX.g(a,b):lX.call(null,a,b),$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.NF,$CLJS.rQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NF],null);return lX.g?lX.g(a,b):lX.call(null,a,b)},$CLJS.ZO,new $CLJS.h(null,1,[EX,oX],null),$CLJS.LN,new $CLJS.h(null,1,[EX,function(a){a=lX.g?lX.g(a,$CLJS.NF):lX.call(null,a,$CLJS.NF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.LE),d=$CLJS.J.g(b,$CLJS.$E);a=$CLJS.J.g(b,$CLJS.dF);b=$CLJS.n(c)?$CLJS.Wk.j(b,$CLJS.LE,bX):b;d=$CLJS.n($CLJS.GA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Wk.j(b,$CLJS.$E,bX):b;return $CLJS.n(a)?$CLJS.Wk.j(d,$CLJS.dF,fX):d}],null)],null),$CLJS.Ny,
new $CLJS.h(null,1,[$CLJS.wO,$CLJS.Td],null),$CLJS.CN,new $CLJS.h(null,1,[EX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.pj),d=$CLJS.J.g(b,$CLJS.BJ),e=$CLJS.J.g(b,$CLJS.bj);a=$CLJS.J.g(b,HX);b=$CLJS.n(e)?$CLJS.Wk.j(b,$CLJS.bj,fX):b;c=$CLJS.n(c)?$CLJS.Wk.j(b,$CLJS.pj,bX):b;d=$CLJS.n(d)?$CLJS.Wk.j(c,$CLJS.BJ,function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):c;d=$CLJS.n(a)?$CLJS.CX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[HX,yla],null),function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):d;return $CLJS.n(a)?
$CLJS.CX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[HX,ula],null),function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):d}],null),$CLJS.xN,function(a){return null==a?null:bX(a)},$CLJS.ZO,new $CLJS.h(null,1,[EX,oX],null),LX,bX],null),lX=function lX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
lX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.HA(Gla,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=bX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,lX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=bX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,lX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(jX(a))?MX.h(a):$CLJS.wd(a)?$CLJS.Vk.g(function(e){return lX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),EX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.qE("Error normalizing form: {0}",$CLJS.H([$CLJS.AV(d)])),new $CLJS.h(null,3,[$CLJS.eA,a,$CLJS.tl,c,xla,b],null),d);}throw e;}};lX.A=1;lX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var qX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(jX(f))?$CLJS.A(f):null},e,a,b,c,d)}();qX.m(null,$CLJS.ci,function(a){return a});
qX.m(null,$CLJS.qG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(kX($CLJS.qG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return qX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,c,$CLJS.Fe($CLJS.kl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,b,$CLJS.Fe(a)],null)});
qX.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,b,a],null)});qX.m(null,$CLJS.dL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(jX(a))?qX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,a,null],null)});
qX.m(null,KX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,b,new $CLJS.h(null,1,[$CLJS.yB,a],null)],null)});qX.m(null,zla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=rX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=rX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,a,$CLJS.R.j(c,$CLJS.MN,b)],null)});
qX.m(null,Ala,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=rX(a);return $CLJS.Se.v($CLJS.OV,a,$CLJS.R,$CLJS.H([$CLJS.BP,b]))});
qX.m(null,JX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=rX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.yB);$CLJS.Va(d)||$CLJS.TS.g(d,b)?a=$CLJS.Se.v($CLJS.OV,a,$CLJS.R,$CLJS.H([$CLJS.wG,b])):(c=$CLJS.QA($CLJS.Ly),$CLJS.n($CLJS.PA("metabase.mbql.util",c))&&(b=$CLJS.qE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.OA("metabase.mbql.util",c,$CLJS.Hw(),b):$CLJS.OA("metabase.mbql.util",c,$CLJS.Hw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),qX.h(new $CLJS.P(null,3,5,$CLJS.Q,[JX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
qX.m(null,$CLJS.JV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=rX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,b,$CLJS.R.j(e,$CLJS.CR,$CLJS.kl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.LE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var PX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Bs,$CLJS.Ds],null)),QX=null,RX=0,SX=0;;)if(SX<RX){var TX=QX.X(null,SX);qX.m(null,TX,function(){return function(a){return sX(a)}}(PX,QX,RX,SX,TX));SX+=1}else{var UX=$CLJS.y(PX);if(UX){var VX=UX;if($CLJS.Ad(VX)){var WX=$CLJS.lc(VX),Hla=$CLJS.mc(VX),Ila=WX,Jla=$CLJS.D(WX);PX=Hla;QX=Ila;RX=Jla}else{var XX=$CLJS.A(VX);qX.m(null,XX,function(){return function(a){return sX(a)}}(PX,QX,RX,SX,XX,VX,UX));PX=$CLJS.B(VX);QX=null;RX=0}SX=0}else break}
qX.m(null,$CLJS.ZF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,rX(a),rX(b)],null),c)});qX.m(null,$CLJS.CG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=rX(a);a=$CLJS.n(gX($CLJS.qG,a))?$CLJS.OV.l(c,$CLJS.Lk,$CLJS.H([$CLJS.wG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CG,a],null),b)});
for(var YX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.hG,$CLJS.BG,$CLJS.pG,$CLJS.iG,$CLJS.fs,$CLJS.HG,$CLJS.cs,$CLJS.es,$CLJS.Zr,$CLJS.as,$CLJS.UF,$CLJS.aG,$CLJS.SF,$CLJS.cG,$CLJS.TF],null)),ZX=null,$X=0,aY=0;;)if(aY<$X){var bY=ZX.X(null,aY);qX.m(null,bY,function(){return function(a){return tX(a)}}(YX,ZX,$X,aY,bY));aY+=1}else{var cY=$CLJS.y(YX);if(cY){var dY=cY;if($CLJS.Ad(dY)){var eY=$CLJS.lc(dY),Kla=$CLJS.mc(dY),Lla=eY,Mla=$CLJS.D(eY);YX=Kla;ZX=Lla;$X=Mla}else{var fY=$CLJS.A(dY);qX.m(null,fY,
function(){return function(a){return tX(a)}}(YX,ZX,$X,aY,fY,dY,cY));YX=$CLJS.B(dY);ZX=null;$X=0}aY=0}else break}qX.m(null,wla,function(){return null});qX.m(null,$CLJS.CM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,qX.h(b),a],null)});
qX.m(null,GX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=qX.h;var e=$CLJS.Q;b=qX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,vla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.vE,a],null);return c.call(qX,new $CLJS.P(null,3,5,e,[$CLJS.CM,b,a],null))});
for(var gY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yx,$CLJS.rG],null)),hY=null,iY=0,jY=0;;)if(jY<iY){var kY=hY.X(null,jY);qX.m(null,kY,function(){return function(a){return uX(a)}}(gY,hY,iY,jY,kY));jY+=1}else{var lY=$CLJS.y(gY);if(lY){var mY=lY;if($CLJS.Ad(mY)){var nY=$CLJS.lc(mY),Nla=$CLJS.mc(mY),Ola=nY,Pla=$CLJS.D(nY);gY=Nla;hY=Ola;iY=Pla}else{var oY=$CLJS.A(mY);qX.m(null,oY,function(){return function(a){return uX(a)}}(gY,hY,iY,jY,oY,mY,lY));gY=$CLJS.B(mY);hY=null;iY=0}jY=0}else break}
for(var pY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.kG,$CLJS.MG,$CLJS.WF,$CLJS.FG,$CLJS.DG,$CLJS.co,$CLJS.nk,$CLJS.EG,$CLJS.XF],null)),qY=null,rY=0,sY=0;;)if(sY<rY){var tY=qY.X(null,sY);qX.m(null,tY,function(){return function(a){return vX(a)}}(pY,qY,rY,sY,tY));sY+=1}else{var uY=$CLJS.y(pY);if(uY){var vY=uY;if($CLJS.Ad(vY)){var wY=$CLJS.lc(vY),Qla=$CLJS.mc(vY),Rla=wY,Sla=$CLJS.D(wY);pY=Qla;qY=Rla;rY=Sla}else{var xY=$CLJS.A(vY);qX.m(null,xY,function(){return function(a){return vX(a)}}(pY,qY,rY,sY,
xY,vY,uY));pY=$CLJS.B(vY);qY=null;rY=0}sY=0}else break}qX.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,rX(b),a],null)});
for(var yY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,$CLJS.zG],null)),zY=null,AY=0,BY=0;;)if(BY<AY){var CY=zY.X(null,BY);qX.m(null,CY,function(){return function(a){return wX(a)}}(yY,zY,AY,BY,CY));BY+=1}else{var DY=$CLJS.y(yY);if(DY){var EY=DY;if($CLJS.Ad(EY)){var FY=$CLJS.lc(EY),Tla=$CLJS.mc(EY),Ula=FY,Vla=$CLJS.D(FY);yY=Tla;zY=Ula;AY=Vla}else{var GY=$CLJS.A(EY);qX.m(null,GY,function(){return function(a){return wX(a)}}(yY,zY,AY,BY,GY,EY,DY));yY=$CLJS.B(EY);zY=null;AY=0}BY=0}else break}
qX.m(null,$CLJS.KG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,qX.h(b),qX.h(a)],null)});
qX.m(null,$CLJS.$F,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(qX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,b],null)),lX.l(a,$CLJS.H([mX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[qX.h(v),qX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[qX.h(m),qX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
qX.m(null,$CLJS.JF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,qX.h(a),$CLJS.E.g(0,b)?1:qX.h(b)],null),$CLJS.hf.g(qX,c))});
var nX=function nX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,nX.h?nX.h(f):nX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.Ht(a,nX);if($CLJS.n(jX(a))){var c=function(){try{return qX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.QA($CLJS.Jy);if($CLJS.n($CLJS.PA("metabase.mbql.normalize",e))){var f=$CLJS.SE("Invalid clause:");f instanceof Error?$CLJS.OA("metabase.mbql.normalize",e,$CLJS.Hw.l($CLJS.H([a])),f):$CLJS.OA("metabase.mbql.normalize",e,$CLJS.Hw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.qE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.AV(d)])),new $CLJS.h(null,1,[$CLJS.hS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(nX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Vk.g(nX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(nX),a):a},xX=function xX(a){return function f(d,e){try{if($CLJS.n(function(){var x=dX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=dX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.$k.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.oE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.is,null,$CLJS.qt,null,$CLJS.LG,null,$CLJS.hs,null,
GX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(NX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.pE(xX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.$k.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},HY=function HY(a){return function f(d,e){try{var k=dX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.nG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.gG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,FX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,IX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,FX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,IX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.nG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nG,rX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.gG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,rX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.$k.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.$k.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.ml.h($CLJS.hf.g(HY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.eX(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},AX=$CLJS.Tk.g(nX,function(a){var b=BX($CLJS.fG.h(a))?$CLJS.Wk.j(a,$CLJS.fG,lla):a;b=BX($CLJS.eG.h(a))?$CLJS.Wk.j(b,$CLJS.eG,mla):b;b=BX($CLJS.$E.h(a))?$CLJS.Wk.j(b,$CLJS.$E,$CLJS.Ve($CLJS.Vk,pX)):b;b=BX($CLJS.WR.h(a))?$CLJS.Wk.j(b,$CLJS.WR,HY):b;return BX($CLJS.QQ.h(a))?$CLJS.Wk.j(b,$CLJS.QQ,ola):b}),Wla=new $CLJS.h(null,3,[$CLJS.rQ,$CLJS.Td,$CLJS.NF,new $CLJS.h(null,2,[$CLJS.QQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.rQ);if($CLJS.n(b))return a=
$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.rQ,$CLJS.NF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rQ],null),a=DX.g?DX.g(a,b):DX.call(null,a,b),$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.NF,$CLJS.rQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NF],null);return DX.g?DX.g(a,b):DX.call(null,a,b)},$CLJS.LN,new $CLJS.h(null,1,[EX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NF],null);return DX.g?DX.g(a,b):DX.call(null,a,b)}],null)],null),LX,$CLJS.Td],null),DX=function DX(a){switch(arguments.length){case 1:return DX.h(arguments[0]);
case 2:return DX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};DX.h=function(a){return DX.g(a,$CLJS.Cf)};DX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.HA(Wla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?sla(a,b):$CLJS.wd(a)?tla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.eA,a,$CLJS.tl,b],null),d);throw d;}};DX.A=2;
$CLJS.IY=function(){var a=$CLJS.Tk.l(DX,rla,pla,$CLJS.H([lX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.qE("Error normalizing query: {0}",$CLJS.H([$CLJS.AV(c)])),new $CLJS.h(null,1,[$CLJS.NF,b],null),c);}throw d;}}}();$CLJS.JY=function JY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.IY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Ft(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=JY.g?JY.g(f,b):JY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};