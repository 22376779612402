var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var yna,zna,Ana,Bna,Cna,c1;
$CLJS.$0=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.FA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.FV("Default period","Default periods",e);case "millisecond":return $CLJS.FV("Millisecond","Milliseconds",e);case "second":return $CLJS.FV("Second","Seconds",e);case "minute":return $CLJS.FV("Minute","Minutes",e);case "hour":return $CLJS.FV("Hour","Hours",e);case "day":return $CLJS.FV("Day","Days",e);case "week":return $CLJS.FV("Week","Weeks",
e);case "month":return $CLJS.FV("Month","Months",e);case "quarter":return $CLJS.FV("Quarter","Quarters",e);case "year":return $CLJS.FV("Year","Years",e);case "minute-of-hour":return $CLJS.FV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.FV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.FV("Day of week","Days of week",e);case "day-of-month":return $CLJS.FV("Day of month","Days of month",e);case "day-of-year":return $CLJS.FV("Day of year","Days of year",e);case "week-of-year":return $CLJS.FV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.FV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.FV("Quarter of year","Quarters of year",e);default:return f=$CLJS.Xt($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.It(" ",$CLJS.ee($CLJS.CA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.a1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.nC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.lC(f)},e,a,b,c,d)}();yna=$CLJS.Xe($CLJS.N);zna=$CLJS.Xe($CLJS.N);
Ana=$CLJS.Xe($CLJS.N);Bna=$CLJS.Xe($CLJS.N);Cna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.nC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.b1=new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.lC,Cna,yna,zna,Ana,Bna);$CLJS.b1.m(null,$CLJS.ci,function(){return null});$CLJS.b1.m(null,$CLJS.kH,function(a){return $CLJS.si.h(a)});c1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.cH,null,$CLJS.fH,null,$CLJS.gH,null,$CLJS.dH,null],null),null);
$CLJS.Dna=$CLJS.eg.j($CLJS.Cf,$CLJS.Tk.g($CLJS.Uk.h(c1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.eu,$CLJS.kH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.uk)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.wH);$CLJS.Ena=$CLJS.Vk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.eu,$CLJS.kH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.fu)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.rH);
$CLJS.Fna=$CLJS.eg.j($CLJS.Cf,$CLJS.Tk.g($CLJS.Uk.h(c1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.eu,$CLJS.kH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.fu)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.xH);$CLJS.H0.m(null,$CLJS.kH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.si);return $CLJS.$0.h(a)});
$CLJS.O0.m(null,$CLJS.kH,function(a,b,c){return $CLJS.kl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.vE,$CLJS.h0.j(a,b,c),$CLJS.F0,$CLJS.dB($CLJS.b1.h(c))],null),$CLJS.im(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.p0],null))]))});
$CLJS.d1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.nC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.lC(l)},e,a,b,c,d)}();$CLJS.d1.m(null,$CLJS.ci,function(){return $CLJS.bh});
$CLJS.e1=function(){function a(d,e,f){return $CLJS.d1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();