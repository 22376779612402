var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DH,EH,GH,HH,Hfa,Ifa,Jfa,IH,FH;$CLJS.BH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.CH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
DH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.zC(b,$CLJS.$C)?$CLJS.mH:$CLJS.zC(b,$CLJS.ZD)?$CLJS.iH:$CLJS.zC(b,$CLJS.zD)?$CLJS.nE:null;return $CLJS.n(b)?$CLJS.xF(b,a):!0};
EH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.BH(function(d){return $CLJS.zC($CLJS.VE(d),$CLJS.kD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Rk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.VE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(DH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
GH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.zt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Gj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EH(b))].join("")}],null),$CLJS.Te(EH)],null)],null)};
HH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.xt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.h(null,1,[$CLJS.co,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)],null)],null)};
Hfa=function(a){return $CLJS.Qd($CLJS.AF,$CLJS.hf.g(function(b){var c=$CLJS.VE(b),d=$CLJS.zC(c,$CLJS.gF);b=d?$CLJS.xF($CLJS.uG,b):d;return $CLJS.n(b)?$CLJS.Sj:c},a))};Ifa=function(a){a=$CLJS.CH(function(b){return!$CLJS.zC(b,$CLJS.kD)},$CLJS.hf.g($CLJS.VE,a));return $CLJS.zC(a,$CLJS.gF)?$CLJS.Zj:a};
Jfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.zC($CLJS.VE(c),$CLJS.kD)},b))?Ifa(b):$CLJS.E.g(a,$CLJS.qt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.zC($CLJS.VE(c),$CLJS.$C)},b)||$CLJS.Qe(function(c){return $CLJS.zC($CLJS.VE(c),$CLJS.zD)},b))?$CLJS.kD:Hfa(b)};IH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.JH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.xt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.VE(a);return $CLJS.Qe(function(d){return DH(d,c)},b)}],null)],null));
$CLJS.X(IH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.h(null,1,[$CLJS.co,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)],null));
var Kfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.xt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.qt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],
null);$CLJS.QG.g($CLJS.hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,GH($CLJS.hs),HH($CLJS.hs)],null));$CLJS.QG.g($CLJS.qt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,GH($CLJS.qt),Kfa,HH($CLJS.qt)],null));$CLJS.HF($CLJS.is,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,IH],null)]));$CLJS.HF($CLJS.LG,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,IH],null)]));
for(var KH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hs,$CLJS.qt,$CLJS.is],null)),LH=null,MH=0,NH=0;;)if(NH<MH){var Lfa=LH.X(null,NH);$CLJS.yF(Lfa,$CLJS.JH);NH+=1}else{var OH=$CLJS.y(KH);if(OH){var PH=OH;if($CLJS.Ad(PH)){var QH=$CLJS.lc(PH),Mfa=$CLJS.mc(PH),Nfa=QH,Ofa=$CLJS.D(QH);KH=Mfa;LH=Nfa;MH=Ofa}else{var Pfa=$CLJS.A(PH);$CLJS.yF(Pfa,$CLJS.JH);KH=$CLJS.B(PH);LH=null;MH=0}NH=0}else break}
$CLJS.UE.m(null,$CLJS.JH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Jfa(a,b)});$CLJS.FF($CLJS.RF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.RF,$CLJS.kF);
for(var RH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.IF,$CLJS.dG],null)),SH=null,TH=0,UH=0;;)if(UH<TH){var Qfa=SH.X(null,UH);$CLJS.FF(Qfa,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));UH+=1}else{var VH=$CLJS.y(RH);if(VH){var WH=VH;if($CLJS.Ad(WH)){var XH=$CLJS.lc(WH),Rfa=$CLJS.mc(WH),Sfa=XH,Tfa=$CLJS.D(XH);RH=Rfa;SH=Sfa;TH=Tfa}else{var Ufa=$CLJS.A(WH);$CLJS.FF(Ufa,$CLJS.H([$CLJS.qt,$CLJS.WD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));RH=$CLJS.B(WH);SH=null;TH=0}UH=0}else break}
for(var YH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,$CLJS.oG,$CLJS.vG],null)),ZH=null,$H=0,aI=0;;)if(aI<$H){var Vfa=ZH.X(null,aI);$CLJS.FF(Vfa,$CLJS.H([$CLJS.qt,$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));aI+=1}else{var bI=$CLJS.y(YH);if(bI){var cI=bI;if($CLJS.Ad(cI)){var dI=$CLJS.lc(cI),Wfa=$CLJS.mc(cI),Xfa=dI,Yfa=$CLJS.D(dI);YH=Wfa;ZH=Xfa;$H=Yfa}else{var Zfa=$CLJS.A(cI);$CLJS.FF(Zfa,$CLJS.H([$CLJS.qt,$CLJS.zj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));YH=$CLJS.B(cI);ZH=null;$H=0}aI=0}else break}$CLJS.FF($CLJS.OF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));
$CLJS.UE.m(null,$CLJS.OF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.zC($CLJS.VE(b),$CLJS.zj)&&$CLJS.zC($CLJS.VE(a),$CLJS.zj)?$CLJS.zj:$CLJS.WD});